const data = {
  wallet: {
    ton: { net: "ton", name: "TON", ref: "https://ton.org" },
    binance: { net: "binance", name: "BINANCE", ref: "https://www.binance.com/activity/referral-entry/CPA?ref=CPA_00ADZC3RSX" },
    bybit: { net: "bybit", name: "BYBIT", ref: "https://www.bybit.com/invite?ref=OGLEXNR" },
    bitget: { net: "bitget", name: "BITGET", ref: "https://share.bitget.com/u/GJ6V7P51" },
    mex: { net: "mex", name: "MEX", ref: "https://www.mexc.com/register?inviteCode=12PV9c" },
    okx: { net: "okx", name: "OKX", ref: "https://okx.com/join/95651189" },
    kucoin: { net: "kucoin", name: "KUCOIN", ref: "https://www.kucoin.com/r/rf/QBA419MX" },
    gate: { net: "gate", name: "GATE", ref: "https://www.gate.io/signup/VLFEBGPCBQ?ref_type=103" },
  },
  player: {
    1: { name: "Zephyr", 1: 150, 2: 225, 3: 337, 4: 506, 5: 759 },
    2: { name: "Liora", 1: 759, 2: 1139, 3: 1708, 4: 2562, 5: 3844 },
    3: { name: "Kael", 1: 3844, 2: 5766, 3: 8649, 4: 12974, 5: 19461 },
    4: { name: "Selene", 1: 19461, 2: 29192, 3: 43789, 4: 65684, 5: 98526 },
    5: { name: "Thorne", 1: 98526, 2: 147789, 3: 221683, 4: 332525, 5: 498788 },
    6: { name: "Mira", 1: 498788, 2: 748182, 3: 1122274, 4: 1683411, 5: 2525116 },
    7: { name: "Orion", 1: 2525116, 2: 3787674, 3: 5681512, 4: 8522268, 5: 12783402 },
    8: { name: "Aria", 1: 12783402, 2: 19175103, 3: 28762655, 4: 43143982, 5: 64715974 },
    9: { name: "Cypher", 1: 64715974, 2: 97073961, 3: 145610942, 4: 218416413, 5: 327624619 },
  },
  playerPrice: {
    1: { 2: 400, 3: 800, 4: 1600, 5: 3200 },
    2: { 2: 6400, 3: 12800, 4: 25600, 5: 51200 },
    3: { 2: 76800, 3: 115200, 4: 172800, 5: 259200 },
    4: { 2: 388800, 3: 583200, 4: 874800, 5: 1312200 },
    5: { 2: 3546936, 3: 5320392, 4: 7980600, 5: 11970912 },
    6: { 2: 17956368, 3: 26934576, 4: 40401864, 5: 60602784 },
    7: { 2: 181808352, 3: 272712576, 4: 409068864, 5: 613603296 },
    8: { 2: 920404944, 3: 1380607440, 4: 2070911136, 5: 3106366752 },
    9: { 2: 4659550128, 3: 6989325216, 4: 10483987824, 5: 15725981712 },
  },
  rang: {
    1: { name: "Neophyte", xp: 100 },
    2: { name: "Challenger", xp: 1000 },
    3: { name: "Fighter", xp: 10000 },
    4: { name: "Strategist", xp: 100000 },
    5: { name: "Commander", xp: 1000000 },
    6: { name: "Leader", xp: 10000000 },
    7: { name: "Overlord", xp: 100000000 },
    8: { name: "Warlord", xp: 1000000000 },
    9: { name: "Icon", xp: 1000000000 },
  },
  top: {
    1: 10000000,
    2: 4000000,
    3: 1000000,
    4: 500000,
    5: 300000,
    6: 200000,
    7: 100000,
    8: 80000,
    9: 70000,
    10: 60000,
    100: 50000,
  },
  progress: {
    ref: {
      1: { col: 10, prize: 5000000 },
      2: { col: 25, prize: 7000000 },
      3: { col: 40, prize: 30000000 },
      4: { col: 100, prize: 40000000 },
      5: { col: 250, prize: 90000000 },
      6: { col: 400, prize: 120000000 },
      7: { col: 900, prize: 200000000 },
      8: { col: 1600, prize: 400000000 },
      9: { col: 4000, prize: 900000000 },
    },
    tasks: {
      1: { col: 10, prize: 100000 },
      2: { col: 25, prize: 500000 },
      3: { col: 40, prize: 2000000 },
      4: { col: 100, prize: 10000000 },
      5: { col: 250, prize: 90000000 },
      6: { col: 400, prize: 120000000 },
      7: { col: 900, prize: 180000000 },
      8: { col: 1600, prize: 500000000 },
      9: { col: 4000, prize: 1000000000 },
    },
    perk: {
      1: { col: 1, prize: 10000 },
      2: { col: 2, prize: 10000 },
      3: { col: 3, prize: 10000 },
      4: { col: 4, prize: 10000 },
      5: { col: 5, prize: 10000 },
      6: { col: 6, prize: 10000 },
      7: { col: 7, prize: 10000 },
      8: { col: 8, prize: 10000 },
      9: { col: 9, prize: 10000 },
    },
    upgrade: {
      1: { col: 1, prize: 10000 },
      2: { col: 2, prize: 10000 },
      3: { col: 3, prize: 10000 },
      4: { col: 4, prize: 10000 },
      5: { col: 5, prize: 10000 },
      6: { col: 6, prize: 10000 },
      7: { col: 7, prize: 10000 },
      8: { col: 8, prize: 10000 },
      9: { col: 9, prize: 10000 },
    },
  },

  perk: {
    1: { name: "T-REX", type: 1, per: 10000, price: 200000, img: "11.webp" },
    2: { name: "Pteranodon", type: 1, per: 50000, price: 1000000, img: "12.webp" },
    3: { name: "Brachiosaurus", type: 1, per: 90000, price: 1800000, img: "13.webp" },
    4: { name: "Triceratops", type: 1, per: 180000, price: 3600000, img: "14.webp" },
    5: { name: "Stegosaurus", type: 1, per: 200000, price: 4000000, img: "15.webp" },
    6: { name: "n-Triceratops", type: 1, per: 500000, price: 10000000, img: "16.webp" },
    7: { name: "Compsognathus", type: 1, per: 700000, price: 14000000, img: "17.webp" },
    8: { name: "Spinosaurus", type: 1, per: 900000, price: 18000000, img: "18.webp" },
    9: { name: "Stegosaurus", type: 1, per: 1000000, price: 20000000, img: "19.webp" },
    10: { name: "Therizinosaurus", type: 1, per: 3000000, price: 60000000, img: "20.webp" },
    11: { name: "Bow", type: 3, per: 10000, price: 200000, img: "21.webp" },
    12: { name: "Sword", type: 3, per: 50000, price: 1000000, img: "22.webp" },
    13: { name: "N-Sword", type: 3, per: 90000, price: 1800000, img: "23.webp" },
    14: { name: "Bag", type: 3, per: 180000, price: 3600000, img: "24.webp" },
    15: { name: "Rifle", type: 3, per: 200000, price: 4000000, img: "25.webp" },
    16: { name: "Handgun", type: 3, per: 500000, price: 10000000, img: "26.webp" },
    17: { name: "N-Handgun", type: 3, per: 700000, price: 14000000, img: "27.webp" },
    18: { name: "Shotgun", type: 3, per: 900000, price: 18000000, img: "28.webp" },
    19: { name: "N-Rifle", type: 3, per: 1000000, price: 20000000, img: "29.webp" },
    20: { name: "Megagun", type: 3, per: 3000000, price: 60000000, img: "30.webp" },
    21: { name: "Palm", type: 2, per: 10000, price: 200000, img: "1.webp" },
    22: { name: "Garden Tree", type: 2, per: 50000, price: 1000000, img: "2.webp" },
    23: { name: "Spruce", type: 2, per: 90000, price: 1800000, img: "3.webp" },
    24: { name: "Sakura", type: 2, per: 180000, price: 3600000, img: "4.webp" },
    25: { name: "Bush Tree", type: 2, per: 200000, price: 4000000, img: "5.webp" },
    26: { name: "Mirri Tree", type: 2, per: 500000, price: 10000000, img: "6.webp" },
    27: { name: "N-Mirri Tree", type: 2, per: 700000, price: 14000000, img: "7.webp" },
    28: { name: "N-Plam", type: 2, per: 900000, price: 18000000, img: "8.webp" },
    29: { name: "Apple Tree", type: 2, per: 1000000, price: 20000000, img: "9.webp" },
    30: { name: "Clear Tree", type: 2, per: 3000000, price: 60000000, img: "10.webp" },
  },
  wheel: [
    { type: "Bust", prize: "2x1", img: "1.webp" },
    { type: "Bust", prize: "2x3", img: "2.webp" },
    { type: "Bust", prize: "2x7", img: "3.webp" },
    { type: "Bust", prize: "3x1", img: "4.webp" },
    { type: "Bust", prize: "3x3", img: "5.webp" },
    { type: "Bust", prize: "3x7", img: "6.webp" },
    { type: "Bust", prize: "5x1", img: "7.webp" },
    { type: "Bust", prize: "5x3", img: "8.webp" },
    { type: "Bust", prize: "5x7", img: "9.webp" },
    { type: "gold", prize: 500, img: "10.webp" },
    { type: "gold", prize: 10000, img: "11.webp" },
    { type: "gold", prize: 20000, img: "12.webp" },
    { type: "gold", prize: 80000, img: "13.webp" },
  ],
};

export default data;
