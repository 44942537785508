<template>
  <div class="navbar">
    <div>
      <router-link to="/profile">
        <div class="card nav">
          <div class="d-flex"><i class="icon i-profile bg-white x34 br-50"></i></div>
          <div class="pl-3">
            <div class="fs-18px fw-800">{{ user.first.slice(0, 8) }} {{ user.last.charAt(0) }}</div>
            <div class="text-light">{{ app.data.rang[user.lvl].name }}</div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="d-flex">
      <router-link to="/panel" v-if="user?.prava" class="mr-2">
        <div class="card nav"><i class="bi bi-gear fs-22px"></i></div>
      </router-link>
      <router-link to="/wallet" class="mr-2">
        <div class="card nav"><i class="icon i-wallet x34 br-50"></i></div>
      </router-link>
      <a @click="gaidShow('next')" class="mr-2">
        <div class="card nav"><i class="bi bi-question fs-26px"></i></div>
      </a>
      <a @click="$modal('langModal')">
        <div class="card nav">{{ user.lang }}</div>
      </a>
    </div>
  </div>

  <Modal :id="'gaidModal'" :style="'full bg-dark br-0 border-0'" :close="false">
    <div>
      <img v-show="gaid == 1" src="../../assets/images/gaid/1.webp" />
      <img v-show="gaid == 2" src="../../assets/images/gaid/2.webp" />
      <img v-show="gaid == 3" src="../../assets/images/gaid/3.webp" />
      <img v-show="gaid == 4" src="../../assets/images/gaid/4.webp" />
    </div>

    <div style="position: absolute; bottom: 0px; width: 100%">
      <div class="gaidProgress p-2 m-auto mt-1">
        <div class="line" :class="{ active: gaid == 1 }"></div>
        <div class="line" :class="{ active: gaid == 2 }"></div>
        <div class="line" :class="{ active: gaid == 3 }"></div>
        <div class="line" :class="{ active: gaid == 4 }"></div>
      </div>
      <div class="d-flex p-2 mt-5">
        <a @click="$modal('gaidModal')" class="btn skip m-2" style="width: 120px">{{ $t("Skip") }}</a>
        <a @click="gaid == 4 ? $modal('gaidModal') : gaid ++" class="btn claim text-up text-white m-2">
          <b>{{ $t("Next") }}</b>
        </a>
      </div>
    </div>
  </Modal>

  <Modal :id="'joinModal'" style="p-0">
    <div class="text-center p-5">
      <h1 class="m-2 text-up fs-45px">{{ $t("Follow telegram") }}</h1>
      <h4 class="mt-5">
        {{ $t("Follow Telegram to stay up to date on all news, Airdrops, events, influence the future of the game through votes and polls, spread the word and get exclusive rewards!") }}
      </h4>
      <h4 class="mt-2 fw-700">{{ $t("Don't miss the latest news about") }}</h4>
      <h4 class="mt-2 fw-700">Mirrion Beyond!</h4>
    </div>
    <div class="col1 text-center mt-6">
      <a href="https://t.me/MirrionBeyond" target="_blank" class="btn claim text-up p-4">{{ $t("Earn more") }}</a>
    </div>
  </Modal>
  <Modal :id="'langModal'">
    <div class="p-6 pb-3">
      <button class="btn br-9 p-4 pl-5 pr-5 mt-2 mb-2" v-for="(val, key) in lang" :key="key" @click="sendLang(key)">
        <div class="flexBetween w-100">
          <div class="fs-18px fw-800">{{ $t(val) }}</div>
          <div class=""><i v-show="user.lang == key" class="bi bi-check-lg fs-24px text-green"></i></div>
        </div>
      </button>
    </div>
  </Modal>
</template>

<script>
export default {
  data() {
    return {
      gaid: 1,
      lang: { en: "English", uk: "Українська", ru: "Русский", ar: "العربية", de: "Deutsch", es: "Español", fr: "Français", hi: "हिन्दी", ja: "日本語", ko: "한국어", zh: "中文" },
    };
  },
  mounted() {
    window.addEventListener("mousedown", this.handleMouseDown);
    if (Math.floor(Math.random() * 100) + 1 > 75 && this.user.gaid == 0) {
      this.$modal("joinModal");
    }
  },
  methods: {
    async gaidShow(key) {
      this.gaid = 1;
      this.$modal("gaidModal");
      if (this.user.gaid == 0) {
        const response = await this.$post("/main", { act: "gaid" });
        this.$store.dispatch("updateUser", { gaid: 1 });
      }
    },
    sendLang(lang) {
      if (lang == "en" || (this.$i18n.messages[lang] && Object.keys(this.$i18n.messages[lang]).length > 0)) {
        const response = this.$post("/main", { act: "lang", lang: lang });
        this.$i18n.locale = lang;
        this.$store.dispatch("updateUser", { lang: lang });
      } else {
        this.$i18n.locale = "en";
        this.$i18n.silentFallbackWarn = true;
      }
      this.$modal("langModal");
    },
    async logOut() {
      this.$router.push("/");
      this.$cookies.set("token", "");
      this.$store.dispatch("userOut");
    },
  },
};
</script>
