import { createI18n } from "vue-i18n";
import store from "../store";

// Импортируем файлы с переводами
// import en from "../lang/en.json";
import ar from "../lang/ar.json";
import de from "../lang/de.json";
import es from "../lang/es.json";
import fr from "../lang/fr.json";
import hi from "../lang/hi.json";
import ja from "../lang/ja.json";
import ko from "../lang/ko.json";
import ru from "../lang/ru.json";
import uk from "../lang/uk.json";
import zh from "../lang/zh.json";

const lang = "en";
const messages = { ru, uk, ar, de, es, fr, hi, ja, ko, zh };

const i18n = createI18n({
  locale: lang,
  fallbackRoot: true,
  fallbackLocale: "en",
  silentFallbackWarn: true,
  messages,
});

export default i18n; // Экспортируем экземпляр i18n
